<template lang="html">
  <div class="cadastro">

    <div class="flex-row">
      
      <v-text-field
        label="Número do cartão"
        variant="outlined"
        v-model="cardNumber"
        v-maska:[cardNumberMask]
        class="mb-2"
      ></v-text-field>
      
      <v-text-field
        label="Código Verificador"
        variant="outlined"
        v-model="cvv"
        v-maska:[cvvMask]
        class="mb-2"
      ></v-text-field>
    </div>
    
    <div class="flex-row">

      <v-text-field
        label="Primeiro Nome"
        variant="outlined"
        v-model="firstName"
        class="mb-2"
      ></v-text-field>
      
      <v-text-field
        label="Sobrenome"
        variant="outlined"
        v-model="lastName"
        class="mb-2"
      ></v-text-field>

    </div>

    <div class="flex-row">

      <v-text-field
        label="Validade do Cartão"
        variant="outlined"
        v-model="expiresAt"
        v-maska:[expiresAtMask]
        class="mb-2"
      ></v-text-field>
      
      <div class="credit-cards">
        <img class="icon" :src="visa">
        <img class="icon" :src="master">
        <img class="icon" :src="american">
        <img class="icon" :src="diners">
        <img class="icon" :src="discover">
        <img class="icon" :src="hiper">
        <img class="icon" :src="elo">
        <img class="icon" :src="aura">
        <img class="icon" :src="jcb">
      </div>

    </div>

    <v-checkbox
      v-if="action == 'create'"
      v-model="acceptTerms"
      value="accepted"
      color="success"
      label="Estou ciente do pagamento mensal de R$ 9,90 (nove reais e noventa centavos), via cartão de crédito, e da fidelização de 10 (dez) meses no Clube Opus. Não será possível cancelar a assinatura antes deste prazo sem o pagamento da multa de R$ 60,00 (sessenta reais). Aceito o Regulamento e os Termos de Uso do Clube Opus."
    ></v-checkbox>


    <div class="d-flex">

      <v-btn
        v-if="action == 'update'"
        @click="closeDialog"
        class="bg-amber-darken-1 mr-auto"
        rounded
        variant="flat"
      >
        Fechar
      </v-btn>

      <v-btn
        @click="sendForm"
        :disabled="isSending"
        class="bg-amber-darken-1 ml-auto"
        rounded
        variant="flat"
      >
        <v-progress-circular v-if="isSending" indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>
        {{ action == 'create' ? 'Próximo' : 'Atualizar' }}
      </v-btn>

    </div>

  </div>
</template>

<script setup>
import { inject, ref, watch } from 'vue';
import { vMaska } from "maska";
import Button from '@/components/Button.vue';
import visa from '@/assets/cartoes/visa.svg';
import master from '@/assets/cartoes/master.svg';
import american from '@/assets/cartoes/american.svg';
import diners from '@/assets/cartoes/diners.svg';
import discover from '@/assets/cartoes/discover.svg';
import hiper from '@/assets/cartoes/hiper.svg';
import elo from '@/assets/cartoes/elo.svg';
import aura from '@/assets/cartoes/aura.svg';
import jcb from '@/assets/cartoes/jcb.svg';

const props = defineProps({
  action: {
    type: String,
    default: 'create',
  },
  buttonText: {
    type: String,
    default: 'Salvar',
  },
});
const emit = defineEmits(["send-form", "close-dialog"]);
const isSending = ref(false);
const acceptTerms = ref(false);

//expõe isSending
defineExpose({
  isSending
});

//fecha dialog
const closeDialog = () => {
  emit("close-dialog");
}


//retorna os dados do form para o parent
const sendForm = () => {
  isSending.value = true;
  //validação dos campos
  let cn = cardNumber.value.replace(/\D/g,'');
  let cv = cvv.value.replace(/\D/g,'');
  if(cn.length != 16 || cv.length != 3 || expiresAt.value.length != 5 || firstName.value == '' ||  lastName.value == '') {
    isSending.value = false;
    alert('Por favor, preencha todos os campos corretamente');
    return false;
  }
  if(props.action == 'create') {
    if(acceptTerms.value != 'accepted') {
      isSending.value = false;
      alert('Você precisa aceitar os termos do serviço!');
      return false;
    }
  }
  emit(
    "send-form",
    {
      cardNumber: cardNumber.value.replace(/\D/g,''),
      cvv: cvv.value,
      firstName: firstName.value,
      lastName: lastName.value,
      expiresAt: expiresAt.value,
    }
  );
};

//cartão
const cardNumber = ref('');
const cardNumberMask = { mask: '#### #### #### ####' };

//cod verificação
const cvv = ref('');
const cvvMask = { mask: '####' };

//nome
const firstName = ref('');

//sobrenome
const lastName = ref('');

//validade
const expiresAt = ref('');
const expiresAtMask = { mask: '##/##' };

</script>

<style lang="scss" scoped>
.cadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  background-color: white;
  border-radius: 10px;
  p {
      margin-bottom: 16px;
  }
  .flex-row {
      display: flex;
      flex-wrap: wrap;
      gap: 0 26px;
      // aplica estilo a todos filhos.
      > * {
          min-width: 300px;
          max-width: 608px;
      }
  }
  h2 {
    margin-bottom:20px
  }
  .botao {
    align-self: flex-end;
  }
  .icon {
    width: 50px;
    height: 50px;
    margin:0 5px;
  }


}
</style>
